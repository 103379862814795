import React, { FC } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import classnames from 'classnames';

import Image from '@components/imgComp';

import * as styles from './styles.module.scss';

type Props = {
    className?: string;
    uid: string;
    isAmp?: boolean;
};

const PostBanner: FC<Props> = ({ className, category, isAmp = false }) => (
    <StaticQuery
        query={graphql`
            query PostBannerQuery {
                allPrismicBanners {
                    edges {
                        node {
                            data {
                                post_banner {
                                    ...ImageFields
                                }
                            }
                        }
                    }
                }
            }
        `}
        render={({ allPrismicBanners }) => {
            const { post_banner } = allPrismicBanners?.edges[0]?.node?.data;
            if (!post_banner) return null;
            return (
                <a
                    href={`https://start.leoandlea.com/v4?utm_source=wantimes&utm_medium=blog&utm_campaign=${category}-pb`}
                    aria-label="Post Banner Link"
                >
                    <div className={classnames(styles['post_banner'], className)}>
                        {isAmp ? (
                            <amp-img
                                src={post_banner?.url}
                                alt={post_banner?.alt}
                                width={post_banner?.dimensions?.width}
                                height={post_banner?.dimensions?.height}
                                layout="responsive"
                            />
                        ) : (
                            <Image {...post_banner} />
                        )}
                    </div>
                </a>
            );
        }}
    />
);

export default PostBanner;
