import React, { useEffect, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

import PostCard from '@components/cards/post';
import NewPosts from '@components/newPosts';
import PostBanner from '@components/postBanner';

import SEO from '@components/seo';

import categories from '@config/categories';

import * as styles from './styles.module.scss';
import FixedCta from '../components/fixedCta';

const CategoryTemplate: FC<any> = ({ location, pageContext }) => {
    const { t } = useTranslation();
    if (!pageContext?.content) return null;

    useEffect(() => {
        // if (window) {
        //     window.analytics.track('Category Viewed', {
        //         category_name: pageContext?.category,
        //     });
        // }
        trackCustomEvent({
            category: 'Category Viewed',
            action: 'Pageview',
            label: pageContext?.category,
        });
    }, []);

    return (
        <>
            <SEO
                {...location}
                title={t(`category.${pageContext?.category}`)}
                description={t(`category.${pageContext?.category}`)}
            />
            <FixedCta />
            <div className={styles['category_wrapper']}>
                <div className={styles['category_header']}>
                    <h1 style={{ color: categories?.[pageContext?.category]?.color ?? categories.leoandlea.color }}>
                        {t(`h1.${pageContext?.category}`)}
                    </h1>
                    <h2>{t(`category_page.${pageContext?.category}.h2`)}</h2>
                    <p>{t(`category_page.${pageContext?.category}.sub_header`)}</p>
                </div>
                <div className={styles['post_card_grid']}>
                    {pageContext?.content?.cards?.map(({ data, uid }: any, index: number) => (
                        <PostCard
                            loading={index > 2 ? 'lazy' : 'eager'}
                            className={styles['post_card']}
                            {...pageContext}
                            {...data}
                            uid={uid}
                            key={uid}
                        />
                    ))}
                    <PostBanner className={styles['post_banner_grid']} {...pageContext} />
                </div>

                <div className={styles['page_navigation']}>
                    {pageContext?.currentPage > 1 && (
                        <a
                            href={`/category/${pageContext?.category}/${
                                pageContext?.currentPage === 2 ? '' : `${pageContext?.currentPage - 1}/`
                            }${pageContext?.isAmp ? 'amp/' : ''}`}
                        >
                            {t('category_page.prev_page')}
                        </a>
                    )}
                    {pageContext?.currentPage < pageContext?.numPages && (
                        <a
                            href={`/category/${pageContext?.category}/${pageContext?.currentPage + 1}/${
                                pageContext?.isAmp ? 'amp/' : ''
                            }`}
                            className={styles['next']}
                        >
                            {t('category_page.next_page')}
                        </a>
                    )}
                </div>

                <PostBanner className={styles['post_banner']} {...pageContext} />
                <NewPosts {...pageContext} />
            </div>
        </>
    );
};

export default CategoryTemplate;
